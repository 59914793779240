<template>
  <v-dialog :value="itemModal" persistent fullscreen>
    <v-card>
      <v-card-title>{{ thisItem.name }}</v-card-title>
      <v-card-text>
        <v-form v-model="formIsValid" ref="form">
          <v-tabs v-model="tab" show-arrows>
            <v-tab key="Info">Info</v-tab>
            <v-tab key="Recept">Recept</v-tab>
            <v-tab key="Voorraad">Voorraad</v-tab>
            <v-tab key="Prijsafspraken">Prijsafspraken</v-tab>
            <v-tab key="Artikelhistorie">Artikelhistorie</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item key="Info">
              <v-row class="d-flex justify-center">
                <v-col cols="12" sm="4">
                  <v-text-field ref="name" label="Naam" v-model="thisItem.name"
                    :rules="[rules.required]"></v-text-field>
                  <vue-editor v-model="thisItem.description"></vue-editor>
                  <v-text-field type="number" ref="price" label="Prijs" :value="thisItem.price.toFixed(2)"
                    @change="thisItem.price = parseFloat($event)" :rules="[rules.required]"></v-text-field>
                  <v-select ref="VAT.amount" :items="settings.VAT" item-text="name" item-value="amount"
                    v-model="thisItem.VAT" :rules="[rules.required]" label="BTW">
                    <template v-slot:selection="data">
                      {{ data.item.name }} {{ data.item.amount }}%
                    </template>
                    <template v-slot:item="data">
                      {{ data.item.name }} {{ data.item.amount }}%
                    </template>
                  </v-select>
                  <v-select :items="['x', 'kg']" v-model="thisItem.unit" label="Eenheid"
                    :rules="[rules.required]"></v-select>
                  <v-text-field type="number" ref="unitAmount" label="Gewicht" v-model="thisItem.unitAmount"
                    :rules="[rules.required]">
                  </v-text-field>
                  <v-select :items="[
                    { name: 'Krat', type: 'Crate' },
                    { name: 'Doos', type: 'Box' }
                  ]" item-text="name" item-value="type" v-model="thisItem.packaging" label="Verpakking"
                    :rules="[rules.required]"></v-select>
                  <v-text-field type="number" ref="packageAmount" label="Verpakkingsinhoud"
                    v-model="thisItem.packageAmount" :rules="[rules.required, rules.notZero]">
                  </v-text-field>
                  <v-select :items="settings.categories" v-model="thisItem.category" label="Categorie"
                    :rules="[rules.required]"></v-select>
                  <v-text-field type="number" ref="cost" label="Kosten" v-model="thisItem.cost"
                    :rules="[rules.isNull]"></v-text-field>
                  <v-text-field type="text" ref="commodityCode" label="GN-code"
                    v-model="thisItem.commodityCode"></v-text-field>
                  <v-text-field type="number" ref="sort" label="Sort" v-model="thisItem.sort"></v-text-field>
                  <v-checkbox label="Archief" v-model="thisItem.archived"></v-checkbox>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item key="Recept">
              <v-row class="d-flex justify-center">
                <v-col cols="12" sm="4">
                  <label>Ingrediënten</label>
                  <v-tooltip bottom max-width="50vh" attach=".ingredients">
                    <template v-slot:activator="{ on, attrs }">
                      <vue-editor class="ingredients" v-bind="attrs" v-on="on"
                        v-model="thisItem.ingredients"></vue-editor>
                    </template>
                    <span>Allergenen: tarwe, rogge, gerst, haver, spelt, khorasantarwe/ kamut, schaaldieren, eieren,
                      vis, pinda,
                      soja, melk (inclusief lactose), amandelen, hazelnoten, walnoten, cashewnoten, pecannoten,
                      paranoten,
                      pistachenoten, macadamianoten, selderij, mosterd, sesamzaad, zwaveldioxide en sulfiet bij
                      concentraties van
                      meer dan 10 mg SO2 per kilo of liter, lupine, weekdieren</span>
                  </v-tooltip>
                  <v-text-field ref="shelfLifeRefrigerated" type="number" label="Houdbaarheid bij 4 graden"
                    hint="In dagen" v-model.number="thisItem.shelfLifeRefrigerated"></v-text-field>
                  <v-text-field ref="shelfLifeFrozen" type="number" label="Houdbaarheid bij -18 graden" hint="In dagen"
                    v-model.number="thisItem.shelfLifeFrozen"></v-text-field>
                  <v-select v-model="thisItem.labelFields" :items="labelFields" hint="Kies de label velden"
                    label="Labelvelden" multiple item-text="name" item-value="value" return-object></v-select>
                  <v-row v-for="label in thisItem.labelFields" :key="label.value">
                    <v-autocomplete :label="label.name" v-model="thisItem[label.value]" :items="countries"
                      item-text="cname" item-value="ccode"></v-autocomplete>
                  </v-row>
                  <v-checkbox v-model="thisItem.frozen" label="Bevroren"></v-checkbox>
                  <v-checkbox v-model="thisItem.halal" label="Halal"></v-checkbox>
                  <v-text-field ref="lotNumber" type="text" label="Lotnummer"
                    v-model="thisItem.lotNumber"></v-text-field>
                  <v-select label="Leverancier" :value="thisItem.supplierNumber" item-text="name"
                    item-value="supplierNumber" :items="suppliers" v-model="thisItem.supplierNumber"></v-select>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item key="Voorraad">
              <v-row>
                <v-col>
                  <v-text-field type="number" ref="stock" label="Voorraad"
                    v-model.number="thisItem.stock"></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item key="Prijsafspraken">
              <v-row no-gutters>
                <v-col cols="3">
                  <v-text-field label="Zoek naar klant" v-model="searchClient" clearable></v-text-field>
                </v-col>
              </v-row>
              <v-data-table :headers="headers" :items="thisItem.fixedPrices" class="elevation-1" :search="searchClient">
                <template v-slot:[`item.price`]="props">
                  <v-edit-dialog cancel-text="Annuleren" save-text="Opslaan" large @save="updateFixedPrice(props.item)">
                    {{ toCurrency(props.item.price) }}
                    <template v-slot:input>
                      <v-text-field v-model="props.item.price" label="Prijs"></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.created`]="{ item }">
                  <td>
                    {{ new Date(item.created).toLocaleDateString("nl-NL") }}
                  </td>
                </template>
                <template v-slot:[`item.updated`]="{ item }">
                  <td v-if="item.updated">
                    {{ new Date(item.updated).toLocaleDateString("nl-NL") }}
                  </td>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small @click="deleteFixedPrice(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item key="Artikelhistorie">
              <v-row>
                <v-col cols="12" sm="3">
                  <div class="d-flex">
                    <v-checkbox label="Alles" v-model="allDates"></v-checkbox>
                    <date-picker :value="fromDate" label="Datum" :dateFormatted="fromDateFormatted" :disabled="allDates"
                      @changeDate="changeFromDate"></date-picker>
                  </div>
                </v-col>
                <v-col cols="12" sm="3">
                  <date-picker :value="toDate" label="t/m" :dateFormatted="toDateFormatted" :disabled="allDates"
                    @changeDate="changeToDate"></date-picker>
                </v-col>
                <v-col cols="12" sm="3">
                  <search-client :searchString="searchClientString" :noResults="false"
                    @setSearchString="setSearchClientString" @get-results="selectClient"
                    @clear-input="clearClient"></search-client>
                </v-col>
                <v-col><v-btn @click="fetchItemHistory" color="primary">Verzenden</v-btn></v-col>
              </v-row>
              <v-data-table :items="itemHistory" :headers="itemHistoryHeaders" :options.sync="options"
                @update:options="fetchItemHistory" :server-items-length="itemHistoryLength">
                <template v-slot:[`item.date`]="{ item }">
                  {{ toDateString(item.date) }}
                </template>
                <template v-slot:[`item.price`]="{ item }">
                  {{ toCurrency(item.price) }}
                </template>
                <template v-slot:[`item.netTotal`]="{ item }">
                  {{ toCurrency(item.netTotal) }}
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-footer fixed class="d-flex justify-center">
          <v-btn-toggle>
            <v-btn color="primary" @click="openPromptModal">Verwijder</v-btn>
            <v-btn color="primary" @click="closeItemModal">Annuleer</v-btn>
            <v-btn color="primary" @click="submit">Opslaan</v-btn>
          </v-btn-toggle>
        </v-footer>
      </v-card-actions>
    </v-card>
    <prompt-modal v-if="promptModal" @confirm="deleteItem" @cancel="closePromptModal" :promptModal="promptModal"
      promptTitle="Verwijder artikel" promptText="Weet u zeker dat u dit artikel wilt verwijderen?">
      <table>
        <tr>
          <th>Artikelnr:</th>
          <td>{{ thisItem.itemNumber }}</td>
        </tr>
        <tr>
          <th>Naam:</th>
          <td>{{ thisItem.name }}</td>
        </tr>
      </table>
    </prompt-modal>
  </v-dialog>
</template>
<script>
import { errorHandler, fetchGET, fetchPOST, successHandler, toDateString, toCurrency } from '../js/functions'
import datePicker from './datePicker.vue'
import searchClient from './searchClient.vue'
import promptModal from "./promptModal"
import { VueEditor } from 'vue2-editor'
import { countries } from '../js/countries'
export default {
  name: "itemModal",
  props: {
    itemModal: Boolean,
    item: Object,
  },
  data() {
    return {
      client: {},
      fromDate: new Date(Date.now()).toISOString().split("T")[0],
      toDate: new Date(Date.now()).toISOString().split("T")[0],
      fromDateFormatted: this.formatDate(
        new Date().toISOString().split("T")[0]
      ),
      toDateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      allDates: false,
      searchClient: "",
      searchClientString: "",
      selectedClient: {},
      itemHistory: [],
      itemHistoryLength: 0,
      itemHistoryHeaders: [
        { text: "Datum", value: "date" },
        { text: "Klant", value: "client" },
        { text: "Artikel", value: "item" },
        { text: "Aantal", value: "amount" },
        { text: "Prijs", value: "price" },
        { text: "Totaal", value: "netTotal" },
        { text: "Order", value: "orderNumber" }
      ],
      options: { itemsPerPage: 5, page: 1 },
      promptModal: false,
      tab: null,
      formIsValid: false,
      thisItem: JSON.parse(JSON.stringify(this.item)),
      collis: 0,
      rules: {
        required: value => !!value || value === 0 || "Veld verplicht.",
        isNull: value => value >= 0 || "Minimaal 0",
        notZero: value => value > 0 || "Moet groter dan 0",
        counter: value => value.length <= 20 || "Max 20 karakters"
      },
      headers: [
        { text: "Klantnr", value: "clientNumber", filterable: false },
        { text: "Klant", value: "clientName", filterable: true },
        { text: "Prijs", value: "price", filterable: false },
        { text: "Aangemaakt", value: "created", filterable: false },
        { text: "Gewijzigd", value: "updated", filterable: false },
        { text: "", value: "actions" }
      ],
      countries: countries,
      suppliers: [],
      labelFields: [
        { name: "Geboren in", value: "birthCountry" },
        { name: "Gehouden in", value: "residenceCountry" },
        { name: "Gemest in", value: "fattenedCountry" },
        { name: "Geslacht in", value: "slaughterCountry" },
      ],
    }
  },
  methods: {
    toDateString(n) {
      return toDateString(n)
    },
    toCurrency(n) {
      return toCurrency(n)
    },
    async changeDate() {
      let data = {
        itemNumber: this.thisItem.itemNumber,
        fromDate: this.fromDate,
        toDate: this.toDate,
        allDates: this.allDates,
      }
      let response = await fetchGET('event', data)
      console.log(response)
    },
    changeFromDate(date) {
      this.fromDate = date
      this.fromDateFormatted = this.formatDate(date)
      if (this.fromDate > this.toDate) {
        this.toDate = this.fromDate
        this.toDateFormatted = this.fromDateFormatted
      }
      this.changeDate()
    },
    changeToDate(date) {
      this.toDate = date
      this.toDateFormatted = this.formatDate(date)
      if (this.toDate < this.fromDate) {
        this.fromDate = this.toDate
        this.fromDateFormatted = this.toDateFormatted
      }
      this.changeDate()
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split("-")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    setSearchClientString(val) {
      this.searchClientString = val
    },
    selectClient(client) {
      this.selectedClient = client
    },
    clearClient() {
      this.searchClientString = ""
    },
    async fetchItemHistory() {
      try {
        let data = {
          allDates: this.allDates,
          fromDate: this.fromDate,
          toDate: this.toDate,
          clientNumber: this.selectedClient.clientNumber,
          itemNumber: this.thisItem.itemNumber,
          options: this.options
        }
        let res = await fetchGET('fetchItemHistory', data)
        console.log(res)
        this.itemHistory = res[0].value[0] ? res[0].value : []
        this.itemHistoryLength = res[1].value[0] ? res[1].value[0].count : 0
      } catch (e) {
        errorHandler(e, "Fout bij ophalen")
      }
    },
    openPromptModal() {
      this.promptModal = true
    },
    closePromptModal() {
      this.promptModal = false
    },
    submit() {
      this.$refs.form.validate()
      if (this.formIsValid) {
        if (!this.thisItem._id) this.$emit("insert-item", this.thisItem)
        else this.$emit("update-item", this.thisItem)
      }
    },
    deleteItem() {
      this.promptModal = false
      this.$emit("delete-item", this.thisItem)
    },
    async updateFixedPrice(item) {
      try {
        item.price = item.price.replace(",", ".")
        let obj = {
          itemNumber: this.item.itemNumber,
          clientNumber: item.clientNumber,
          price: item.price,
          created: item.created
        }
        let response = await fetchPOST("updateFixedPrice", obj)
        if (response.result.nModified == 0) throw "updateFixedPrice"
        successHandler("Prijsafspraak gewijzigd")
      }
      catch (e) {
        errorHandler(e, "Prijsafspraak niet gewijzigd")
      }
    },
    async deleteFixedPrice(item) {
      console.log(item)
      try {
        let obj = {
          itemNumber: this.thisItem.itemNumber,
          clientNumber: item.clientNumber
        }
        let response = await fetchPOST("deleteFixedPrice", obj)
        if (response.result.nModified == 0) throw "deleteFixedPrice"
        let index = this.thisItem.fixedPrices.findIndex(
          fix => fix.clientNumber == item.clientNumber
        )
        if (index > -1) this.thisItem.fixedPrices.splice(index, 1)
        successHandler("Prijsafspraak verwijderd")
      } catch (e) {
        errorHandler(e, "Prijsafspraak niet verwijderd")
      }
    },
    closeItemModal() {
      this.$emit("close-item-modal")
    }
    ,
    async fetchSuppliers() {
      try {
        let res = await fetchGET("fetchSuppliers", {})
        this.suppliers = res.suppliers
      } catch (e) {
        errorHandler(e, "Fout bij ophalen")
      }
    }
  },
  computed: {
    settings() {
      return this.$store.state.settings
    }
  },
  async mounted() {
    try {
      if (this.thisItem._id) {
        let item = await fetchGET("fetchItem", this.thisItem._id)
        this.thisItem.fixedPrices = item.fixedPrices
      }
      await this.fetchSuppliers()
    }
    catch (e) {
      errorHandler(e, "Fout bij ophalen")
    }
  },
  components: { promptModal, datePicker, searchClient, VueEditor }
}
</script>
<style scoped>
.v-window {
  min-height: 600px;
}

#delete {
  float: left;
  width: 25px;
}

td {
  padding: 0px;
}

#zero {
  width: 152px;
}

#amount {
  font-size: 2em;
}

#unit {
  font-size: 0.6em;
}

#price {
  font-size: 1.3em;
}

#dot {
  font-size: 2em;
  vertical-align: center;
}

.activePrice {
  background: #9ec739;
  color: white;
}

#collis {
  float: right;
  font-size: 1.2em;
  color: black;
}
</style>
